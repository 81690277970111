import { DotLottiePlayer } from '@dotlottie/react-player'

interface LottieAnimationProps {
  assetUrl: string
}

const LottieAnimation = ({ assetUrl }: LottieAnimationProps) => {
  return <DotLottiePlayer src={assetUrl} autoplay loop />
}

export default LottieAnimation
